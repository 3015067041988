import { useQuery } from '@abyss/web/hooks/useQuery';
import GetGroups from './GetGroups.gql';

export const useGroupSearch = (options) => {
  return useQuery(GetGroups, {
    ...options,
    url: '/api/graphql',
    accessor: 'groups',
    requestPolicy: 'no-cache',
  });
};
