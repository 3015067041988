/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { EventPayload, ToastEvent } from '@abyss/web/hooks/useToast/useToast';
import { config } from '@abyss/web/tools/config';
import { dayjs } from '@abyss/web/tools/dayjs';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import {
  SessionTimeoutStatus,
  sessionTimeoutManager,
} from './sessionTimeoutManager';

export const sessionCountdown = sessionCountdownFn();

function sessionCountdownFn() {
  let timeoutCallback: () => Promise<void> = async () => {};
  let toast: {
    show: ToastEvent;
    hide: ToastEvent;
    clean: ToastEvent;
    cleanQueue: ToastEvent;
    update: ToastEvent;
  } = {
    show: () => {},
    hide: () => {},
    clean: () => {},
    cleanQueue: () => {},
    update: () => {},
  };
  let showingToast = false;

  const local = config('APP_ENV') === 'local';

  const toastProps: EventPayload = {
    id: 'session',
    withCloseButton: true,
    title: 'Session Inactivity',
    message: ``,
    autoClose: false,
    icon: <IconMaterial icon="warning_amber" />,
    variant: 'warning',
    onOpen: addListenersThatWillCloseTheAlert,
    onClose: onToastClose,
  };

  const closeToastEvents = [
    'keydown',
    'click',
    'beforeunload',
  ] as (keyof DocumentEventMap)[];

  return {
    initCountdown,
    setOnTimedOut,
    setToast: (_toast: typeof toast) => {
      toast = _toast;
    },
  };

  function setOnTimedOut(callback: () => Promise<void>) {
    timeoutCallback = callback;
  }

  function initCountdown() {
    showingToast = false;
    sessionTimeoutManager.startSessionTimeout({
      ...getTimes(),
      onTick,
    });
  }

  function onTick({ msToTimeout, prompting, timedOut }: SessionTimeoutStatus) {
    if (!showingToast && prompting) {
      showingToast = true;
      toast.show({ ...toastProps, message: getToastMessage(msToTimeout) });
    } else if (prompting && !timedOut) {
      toast.update({
        ...toastProps,
        message: getToastMessage(msToTimeout),
      });
    } else if (timedOut) {
      sessionTimeoutManager.stopSessionTimeout();
      timeoutCallback();
      // @ts-ignore
      toast.hide(toastProps.id);
    } else if (showingToast) {
      // @ts-ignore
      toast.hide(toastProps.id);
    }
  }

  function onToastClose() {
    removeListenersThatWillCloseTheAlert();
    showingToast = false;
  }

  function continueSession() {
    sessionTimeoutManager.resetTimer();
    // @ts-ignore
    toast.hide(toastProps.id);
  }

  function getTimes() {
    const oneHour = 60;
    const timeoutAfterMinutes = local ? oneHour * 24 : oneHour / 2;
    const promptAfterMinutes = local ? oneHour * 23 : 25;
    return {
      promptAfterMinutes,
      timeoutAfterMinutes,
    };
  }

  function getToastMessage(msToTimeout: number) {
    const formattedTime = local
      ? dayjs.duration(msToTimeout).format('HH:mm:ss')
      : dayjs.duration(msToTimeout).format('mm:ss');
    return `You will be logged out in ${formattedTime} minutes due to inactivity - please close this alert or hit any key to stay logged in.`;
  }

  function addListenersThatWillCloseTheAlert() {
    closeToastEvents.forEach((e) =>
      document.addEventListener(e, continueSession)
    );
  }

  function removeListenersThatWillCloseTheAlert() {
    closeToastEvents.forEach((e) =>
      document.removeEventListener(e, continueSession)
    );
  }
}
