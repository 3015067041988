import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@abyss/web/ui/Link';
import { styled } from '@abyss/web/tools/styled';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { useCssProps } from '@abyss/web/hooks/useCssProps';

const StyledBreadcrumb = styled('li', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const CurrentBreadcrumb = styled('span', {
  static: {
    fontSize: '$md',
    color: '$gray8',
    fontWeight: '$medium',
  },
  dynamic: ({ cssProps }) => {
    const { size } = cssProps;
    return {
      fontSize: size,
    };
  },
});

const StyledDividerContainer = styled('div', {
  static: {
    lineHeight: '0px',
    fontWeight: '$medium',
  },
  dynamic: ({ cssProps }) => {
    const { size, space } = cssProps;
    return {
      fontSize: size,
      marginLeft: space,
      marginRight: space,
    };
  },
});

export const Breadcrumb = ({
  isCurrent,
  title,
  divider,
  href,
  size,
  sizes,
  space,
  ...props
}) => {
  const abyssProps = useAbyssProps({ props });
  const cssProps = useCssProps({ size, sizes, space });

  return (
    <StyledBreadcrumb>
      {isCurrent ? (
        <CurrentBreadcrumb
          {...abyssProps('breadcrumb-current')}
          cssProps={cssProps}
        >
          {title}
        </CurrentBreadcrumb>
      ) : (
        <React.Fragment>
          <Link
            {...abyssProps('breadcrumb-link')}
            href={href}
            fontWeight="$medium"
            variant="default"
            cssProps={cssProps}
          >
            {title}
          </Link>
          <StyledDividerContainer
            {...abyssProps('breadcrumb-divider-container')}
            aria-hidden="true"
            cssProps={cssProps}
          >
            {divider}
          </StyledDividerContainer>
        </React.Fragment>
      )}
    </StyledBreadcrumb>
  );
};

Breadcrumb.displayName = '@abyss/web/ui/Breadcrumb';

Breadcrumb.propTypes = {
  href: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sizes: PropTypes.shape({}),
  space: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  divider: PropTypes.node,
};

Breadcrumb.defaultProps = {
  isCurrent: false,
  size: '$md',
  sizes: {
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  space: 8,
  divider: '/',
};
