import React from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Breadcrumbs } from "@src/abyss/web/ui/Breadcrumbs";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";

export const HelpPage = () => {

  return (
    <React.Fragment>

      <div tabIndex={-1}
           style={{padding: '15px', margin: '10px', maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto'}}>
        <Breadcrumbs
          // divider={'...'}
          isDarkMode
          leadingIcon={<IconMaterial icon="home" size="24px"/>}
          items={[
            {title: 'Home', href: '/'},
            {title: 'Help', href: '/help'},
          ]}
        />
      </div>


      <PageBody>
        <br/>
        <Heading offset={1} textAlign="center">Welcome to the dFEDS Help Page</Heading>
        <br/>
        <br/>

        <br/><br/><br/>
        <Heading offset={6}
                 color="#ff0000"
                 textAlign="center">
          Under construction - coming soon...
        </Heading>
      </PageBody>


    </React.Fragment>
  );
};
