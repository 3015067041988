import { styled } from '@abyss/web/tools/styled';

export const Card = styled('div', {
  backgroundColor: '$white',
  borderRadius: 8,
  border: '1px solid $gray3',
  boxShadow: `
    rgb(0 0 0 / 5%) 0px 1px 3px,
    rgb(0 0 0 / 5%) 0px 10px 15px -5px,
    rgb(0 0 0 / 4%) 0px 7px 7px -5px
  `,

  '& + &': {
    marginTop: 30,
  },
});
