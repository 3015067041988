import { ajax } from '@src/common/ajax';
import { ApiUrl } from '@src/common/ajax/apiUrl';
import { AuthResponse } from './types';

interface AuthResult {
  authenticated: boolean;
  validToken: boolean;
  secureGroups: string[];
  roles: string[];
  error: boolean;
}

export async function fetchAuthStatus(): Promise<AuthResult> {
  const query = `
  query Groups {
    groups
  }`;

  const body = JSON.stringify({ operationName: 'Groups', query });

  const defaultResponse: AuthResponse = {
    data: {
      groups: {
        loggedIn: false,
        roles: [],
        secureGroups: [],
        tokenExpired: false,
        tokenValidated: false,
      },
    },
  };

  const resp = await ajax(defaultResponse, ApiUrl.apiGroups(), { body });

  const { status, success, payload } = resp;
  const { secureGroups, roles } = payload.data.groups;

  const authenticated = status === 200;
  const invalidToken = status === 403;
  return {
    authenticated,
    validToken: authenticated && !invalidToken,
    error: !success && !invalidToken,
    secureGroups,
    roles,
  };
}
