import { isEmpty } from 'lodash';

const removeEmptyProps = (props) => {
  if (isEmpty(props)) return props;

  return Object.entries(props).reduce((acc, [key, value]) => {
    if (!isEmpty(value)) acc[key] = value;
    return acc;
  }, {});
};

const epimFilterBuilder = {
  with: (filter) => {
    return {
      and: (source) => {
        return {
          get: () => {
            const { sources, ...baseFilter } = removeEmptyProps(filter);
            const { firstName, lastName, ...org } = baseFilter;
            const { orgName, ...practitioner } = baseFilter;
            return { org, pract: practitioner }[source];
          },
        };
      },
    };
  },
};

export const sourceUtils = {
  removeEmptyProps,
  epimFilterBuilder,
};
