import { useQuery } from '@abyss/web/hooks/useQuery';
import GetProviderTypesSearch from './GetProviderTypesSearch.gql';

export const useProviderTypesSearch = (options) => {
  return useQuery(GetProviderTypesSearch, {
    ...options,
    url: '/api/graphql',
    accessor: 'providerTypes',
  });
};
