import { useQuery } from "@abyss/web/hooks/useQuery";
// @ts-ignore
import GetHasRole from './GetHasRole.gql';

export const useHasRole = (options) => {
	return useQuery(GetHasRole, {
		...options,
		url: '/api/graphql',
		// requestPolicy: 'no-cache',
	});
};
