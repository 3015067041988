/* eslint-disable @typescript-eslint/no-use-before-define */

interface Times {
  promptAt: number;
  timeoutAt: number;
}
interface Props {
  promptAfterMinutes: number;
  timeoutAfterMinutes: number;
  onTick: (status: SessionTimeoutStatus) => void;
}

export interface SessionTimeoutStatus {
  msToPrompt: number;
  msToTimeout: number;
  prompting: boolean;
  timedOut: boolean;
}

export const sessionTimeoutManager = sessionTimeoutManagerFn();

function sessionTimeoutManagerFn() {
  const lsKey = 'sessionTimeout';

  const options: Props = {
    promptAfterMinutes: 25,
    timeoutAfterMinutes: 30,
    onTick: () => {},
  };

  let ticker: NodeJS.Timer;
  let debounceTimeout: NodeJS.Timer;

  return {
    startSessionTimeout,
    stopSessionTimeout,
    resetTimer,
  };

  function debounce(fn: Function) {
    clearTimeout(debounceTimeout);
    setTimeout(fn, 300);
  }

  function minutes(mins: number) {
    return 1000 * 60 * mins;
  }

  function readLs(): Times {
    return JSON.parse(localStorage.getItem(lsKey)!);
  }
  function writeLs(times: Times) {
    localStorage.setItem(lsKey, JSON.stringify(times));
  }

  function generateTimes(): Times {
    const now = new Date().getTime();
    const promptAt = now + minutes(options.promptAfterMinutes);
    const timeoutAt = now + minutes(options.timeoutAfterMinutes);
    return { promptAt, timeoutAt };
  }

  function debounceResetTimer() {
    debounce(() => {
      writeLs(generateTimes());
    });
  }

  function getTimesRemaining(): SessionTimeoutStatus {
    const now = new Date().getTime();
    const { promptAt, timeoutAt } = readLs();
    const msToPrompt = promptAt >= now ? promptAt - now : 0;
    const msToTimeout = timeoutAt >= now ? timeoutAt - now : 0;
    return {
      msToPrompt,
      msToTimeout,
      prompting: msToPrompt === 0,
      timedOut: msToTimeout === 0,
    };
  }

  function initTimer() {
    clearInterval(ticker);
    const { prompting, timedOut } = getTimesRemaining();
    let alertingCurrent = prompting || timedOut;

    ticker = setInterval(() => {
      const newTimes = getTimesRemaining();
      const alertingNew = newTimes.prompting || newTimes.timedOut;
      if (alertingCurrent === alertingNew) {
        options.onTick(newTimes);
        return;
      }

      const actionFn = alertingNew ? removeActionListeners : addActionListeners;
      actionFn();
      alertingCurrent = alertingNew;
      options.onTick(newTimes);
    }, 1000);
  }

  function addActionListeners() {
    getDefaultActions().forEach((action) => {
      return window.addEventListener(action, debounceResetTimer);
    });
  }

  function removeActionListeners() {
    getDefaultActions().forEach((action) => {
      return window.removeEventListener(action, debounceResetTimer);
    });
  }

  function startSessionTimeout(props: Props) {
    stopSessionTimeout();
    Object.assign(options, props);
    writeLs(generateTimes());
    addActionListeners();
    initTimer();
  }

  function stopSessionTimeout() {
    removeActionListeners();
    clearInterval(ticker);
  }

  function resetTimer() {
    writeLs(generateTimes());
  }

  function getDefaultActions() {
    return [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ];
  }
}
