import React from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Breadcrumbs } from "@src/abyss/web/ui/Breadcrumbs";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";
import { Label } from "@abyss/web/ui/Label";
import { Table } from '@abyss/web/ui/Table';

export const WhatsNewPage = () => {

  return (
    <React.Fragment>

      <div tabIndex={-1}
           style={{padding: '15px', margin: '10px', maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto'}}>
        <Breadcrumbs
          // divider={'...'}
          isDarkMode
          leadingIcon={<IconMaterial icon="home" size="24px"/>}
          items={[
            {title: 'Home', href: '/'},
            {title: "What's New", href: '/whats-new'},
          ]}
        />
      </div>


      <PageBody>
        <Heading offset={1} color="#ff0000" textAlign="center">Current Release</Heading>
        <br/>
        <Table.Container title="Manual Static Table">
          <Table.TableHeader>
            <Table.TableHeaderRow css={{ color: 'black', backgroundColor: '$white' }}>
              <Table.Column> <Label>New Feature</Label></Table.Column>
              <Table.Column> <Label>Desciption</Label></Table.Column>
            </Table.TableHeaderRow>
          </Table.TableHeader>
          <Table.TableBody>
            <Table.Row>
              <Table.Cell css={{ color: '$primary1', backgroundColor: '$gray3' }}>
                <Label>Fraud Case Management</Label>
              </Table.Cell>
              <Table.Cell>Create, update, and manage <strong>Fraud Flag Committee (FFC)</strong> cases, providing full control over fraud cases and their
                associated identifiers.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell css={{ color: '$primary1', backgroundColor: '$gray3' }}>
                <Label>Global Provider Search Across Multiple Sources</Label>
              </Table.Cell>
              <Table.Cell>Perform advanced searches to identify flagged providers across <strong>FFC</strong>, <strong>Optum Rx</strong>,
                and <strong>EPIM Sanctions</strong> with ease,
                consolidating data from multiple systems into one powerful search tool.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell css={{ color: '$primary1', backgroundColor: '$gray3' }}>
                <Label>Single Sign-On (SSO) and Secure Authorisation</Label>
              </Table.Cell>
              <Table.Cell>Streamlined access through integrated <strong>Single Sign-On (SSO)</strong>, ensuring fast and secure authentication.
                <br/>
                Role-based authorisation is managed via group requests through the <strong>Secure platform</strong>, ensuring that access
                control aligns with organizational security policies.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell css={{ color: '$primary1', backgroundColor: '$gray3' }}>
                <Label>NESS Logging</Label>
              </Table.Cell>
              <Table.Cell>All requests to dFEDS are logged to <strong>Ness</strong>, providing a reliable audit trail for future analysis and compliance.
              </Table.Cell>
            </Table.Row>
          </Table.TableBody>
        </Table.Container>


        <br/><br/>
        <Heading offset={1} textAlign="center">Previous Releases</Heading>
        <br/>

      </PageBody>


    </React.Fragment>
  );
};
