import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';

const CardSectionStyle = styled('div', {
  padding: '$md',

  '& + &': {
    borderTop: '1px solid $gray4',
  },
});

export const CardSection = ({ children, ...props }) => {
  if (!children) {
    return null;
  }

  const abyssProps = useAbyssProps(props);

  return (
    <CardSectionStyle {...props} {...abyssProps('card-section')}>
      {children}
    </CardSectionStyle>
  );
};

CardSection.displayName = '@abyss/web/ui/Card.Section';

CardSection.propTypes = {
  children: PropTypes.node,
};

CardSection.defaultProps = {
  children: null,
};
