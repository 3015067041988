import { useQuery } from "@abyss/web/hooks/useQuery";
// @ts-ignore
import GetUpdateRoles from './GetUpdateRoles.gql';

export const useUpdateRoles = (options) => {
	return useQuery(GetUpdateRoles, {
		...options,
		url: '/api/graphql',
		requestPolicy: 'no-cache',
	});
};
