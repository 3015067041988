import { IconMaterialProps } from '@abyss/web/ui/IconMaterial';

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  msid: string;
  version: string;
}

export enum PermissionNames {
  canLogin = 'login',
  canReadFfc = 'read-ffc',
  canWriteFfc = 'write-ffc',
  canSearchFfc = 'search-ffc',
  canSearchCms = 'search-cms',
  canSearchEpim = 'search-epim',
  canSearchOptumRx = 'search-optumrx',
  admin = 'admin',
}

export interface Permissions {
  canLogin: boolean;
  canReadFfc: boolean;
  canWriteFfc: boolean;
  canSearchFfc: boolean;
  canSearchCms: boolean;
  canSearchEpim: boolean;
  canSearchOptumRx: boolean;
  admin: boolean;
}

export interface RoleResponseData {
  hasRole: Partial<Permissions>;
}

export interface AuthStore {
  authStatusKnown: boolean;
  authenticated: boolean;
  validToken: boolean;
  permissions: Permissions;
  secureGroups: string[];
  user: User;
  authorizedNavLinks: NavLinkProps[];
  error: boolean;
}

export interface AuthResponse {
  data: {
    groups: {
      loggedIn: boolean;
      roles: string[];
      secureGroups: string[];
      tokenExpired: boolean;
      tokenValidated: boolean;
    };
  };
}

export interface UserResponse {
  data: {
    user: User;
  };
}

export interface NavLinkProps {
  id: string;
  title: string;
  href: string;
  icon: IconMaterialProps['icon'];
}
