import { useQuery } from '@abyss/web/hooks/useQuery';
import { GetCountryStates } from './GetCountryStatesSearch.gql';

export const useCountryStatesSearch = (options) => {
  return useQuery(GetCountryStates, {
    ...options,
    url: '/api/graphql',
    accessor: 'countryStates',
  });
};
