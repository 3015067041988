import { useRouter } from '@abyss/web/hooks/useRouter';
import React from 'react';

export const GoHome = () => {
  const { navigate, getLocation } = useRouter();
  const location = getLocation();

  React.useEffect(() => {
    /**
     * we may have been routed here by Router cos user typed in some url that we do not know about.
     * So let's change the url path to '/' otherwise it could look weird, for example, if they
     * type '/this/does/not/exist' and they land on the home page but url does not say '/'
     */
    if (location?.pathname !== '/') {
      navigate('/');
    }
  }, [location]);

  return <div>Redirecting....</div>;
};
