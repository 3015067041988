import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { useCssProps } from '@abyss/web/hooks/useCssProps';

const CardTitleStyle = styled('div', {
  static: {
    padding: '$md',
    color: '$gray4',
    fontWeight: '$semibold',
    fontSize: '$lg',
    lineHeight: '$lg',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  dynamic: ({ cssProps }) => {
    return {
      color: cssProps.color,
      backgroundColor: cssProps.backgroundColor,
    };
  },
});

export const CardTitle = ({ backgroundColor, color, ...props }) => {
  const abyssProps = useAbyssProps(props);
  const cssProps = useCssProps({ color, backgroundColor });
  return (
    <CardTitleStyle
      {...props}
      {...abyssProps('card-title')}
      cssProps={cssProps}
    />
  );
};

CardTitle.displayName = '@abyss/web/ui/Card.Title';

CardTitle.propTypes = {
  fullHeight: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

CardTitle.defaultProps = {
  fullHeight: false,
  children: null,
  color: '$gray8',
  backgroundColor: '$gray3',
};
