/* eslint-disable @typescript-eslint/no-use-before-define */
import { getFetch } from './fetchProvider';
import { AjaxResponse } from './types';

interface Props<ResponseType> {
  defaultResponseData: ResponseType;
  input: RequestInfo;
  init?: RequestInit;
}

export async function ajax<ResponseType>(
  defaultResponseData: ResponseType,
  input: RequestInfo,
  init?: RequestInit
): Promise<AjaxResponse<ResponseType>> {
  return handleCall(init?.method || 'post', {
    defaultResponseData,
    input,
    init,
  });
}

async function handleCall<ResponseType>(
  method: string,
  { input, init, defaultResponseData }: Props<ResponseType>
) {
  try {
    const response = await getFetch()(input, getDefaultInit(method, init));
    const json = await response.json();
    const usingFallback = response.status === 204 || !response.ok;
    const data = usingFallback ? defaultResponseData : json;

    const errorInfo = usingFallback ? { ...json } : {};
    return {
      payload: {
        ...errorInfo,
        ...data,
      },
      status: response.status,
      success: response.ok,
    };
  } catch (e) {
    // TODO: - log
    return {
      payload: defaultResponseData,
      status: 500,
      success: false,
    };
  }
}

function getDefaultInit(method: string, init?: RequestInit): RequestInit {
  return {
    ...init,
    method,
    headers: {
      'Content-Type': 'application/json',
      ...init?.headers,
    },
  };
}
