import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { Breadcrumb } from './Breadcrumb';

const BreadcrumbList = styled('ol', {
  display: 'flex',
  flexDirection: 'row',
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

// const useLocationModified = () => {
//   const inRouterContext = useInRouterContext();
//   if (inRouterContext) {
//     return useLocation();
//   }
//   if (typeof window !== 'undefined') {
//     return window.location;
//   }
//   return null;
// };

export const Breadcrumbs = ({ items, ...props }) => {
  const abyssProps = useAbyssProps(props);
  const router = useRouter();
  const location = router.getLocation();

  let currentIndex = items.length - 1;
  if (location) {
    const foundIndex = items.findIndex((item) => {
      return item.href === location.pathname;
    });
    if (foundIndex >= 0) {
      currentIndex = foundIndex;
    }
  }
  const filteredBreadcrumbs = items.slice(0, currentIndex + 1);

  return (
    <nav>
      <BreadcrumbList {...abyssProps('breadcrumbs-root')}>
        {filteredBreadcrumbs.map((item, index) => {
          return (
            <Breadcrumb
              key={item.title}
              {...props}
              {...item}
              isCurrent={index === currentIndex}
              index={index}
            />
          );
        })}
      </BreadcrumbList>
    </nav>
  );
};

Breadcrumbs.displayName = '@abyss/web/ui/Breadcrumbs';

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sizes: PropTypes.shape({}),
  divider: PropTypes.node,
};

Breadcrumbs.defaultProps = {
  items: [],
  size: 'md',
  sizes: {
    md: '16px',
  },
  divider: '/',
};
