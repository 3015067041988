import { useQuery } from '@abyss/web/hooks/useQuery';
// @ts-ignore
import FindProvider from './FindProvider.gql'


export const useFindProvider = (options) => {
  return useQuery(FindProvider, {
    ...options,
    url: '/api/graphql',
    requestPolicy: 'no-cache',
  });
};
