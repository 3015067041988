import { useQuery } from '@abyss/web/hooks/useQuery';
import GetUser from './GetUser.gql';

export const useUserSearch = (options) => {
  return useQuery(GetUser, {
    ...options,
    url: '/api/graphql',
    requestPolicy: 'no-cache',
    accessor: 'user',
  });
};
