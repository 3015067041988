/* eslint-disable arrow-body-style */
import { isEmpty } from 'lodash';
import {
	utils,
	IProviderDisallowedEntity,
	IProviderHeading}
from "./dataGridUtils";

const isDirty = (row, i: IProviderDisallowedEntity) : boolean => {
	const identityAsGridRow = [
		i.id,
		i.idTypeCode,
		i.idTypeValue,
		i.name,
		i.address1,
		i.address2,
		i.stateCode,
		i.city,
		i.postalCode,
		i.decisionCode,
		(i.decisionCode === 'HDCS' ? i.procedureCode : 'N/A for non HDCS'),
		i.decisionDate,
		i.decisionReasonCode,
		i.decisionRemovalDate,
		i.decisionRemovalReason]
	;
	// probably that the most efficient method to compare if both arrays are diff
	return JSON.stringify(row) !== JSON.stringify(identityAsGridRow);
}

const validateUpdate = (gridData) : [boolean, [IProviderDisallowedEntity]] => {
	// map the grid to an object
	const disallowedEntities: [IProviderDisallowedEntity] = utils.toJson(gridData);
	// validate identities
	return [utils.isValid(disallowedEntities), disallowedEntities];
}

const validateNew = (gridData) : [boolean, [IProviderDisallowedEntity]] => {
	// map the grid to an object
	const disallowedEntities: [IProviderDisallowedEntity] = utils.toCreateJson(gridData);
	// validate identities
	return [utils.isValid(disallowedEntities), disallowedEntities];
}

const buildNewRequest = (data, disallowedEntities) : IProviderHeading => {
	return {
		firstName: utils.toNullIfAllowed(data.firstName),
		lastName: utils.toNullIfAllowed(data.lastName),
		middleInitial: utils.toNullIfAllowed(data.middleInitial),
		orgName: utils.toNullIfAllowed(data.orgName),
		providerTypeCode: data.providerTypeCode,
		presentedDate: data.presentedDate,
		requestedDecisionCode: data?.requestedDecisionCode,
		dollarBills: data.dollarBills ? data.dollarBills: 0,// utils.toNullIfAllowed(data.dollarBills),
		notes: utils.toNullIfAllowed(data.notes),
		tipSource: data.tipSource,
		identities: disallowedEntities,
	};
}
const buildUpdateRequest = (fcId, data, disallowedEntities) : IProviderHeading => {
	return {
		id: fcId,
		...buildNewRequest(data, disallowedEntities),
	};
}

/**
 * Checks if any field validation errors are present and reports failure if so.
 * Then gets the fields that have been touched on form and then compares with the fraudCase (fc) state
 * @param form the object that stores the state of 'firstName', 'lastName' fields etc.
 * @param fc the fraudCase object that we use to compare against
 */
const formDirty = (form, fc) : boolean => {// form.formState.isDirty || JSON.stringify(form.formState.dirtyFields) !== '{}';
	// first, if there are any field validation errors then fail
	if (!form.formState.isValid) return false;

	// next check if the header info has changed
	const touched = form.formState.dirtyFields;
	let headerDirty = false;
	// eslint-disable-next-line no-restricted-syntax
	for (const prop in touched) {
		if (!isEmpty(fc[prop]) || !isEmpty(form.getValues(prop))) {
			headerDirty = true;
			break;
		}
	}
	// one last check - the 'presentedDate' which isn't being picked up by formState - TODO: investigate this
	if (fc.presentedDate !== form.getValues('presentedDate')) return true;

	return headerDirty;
}

/**
 * Checks if the grid has changed
 * @param gridData the data to compare against the identities held inside the fraudCase (fc)
 * @param fc the fraudCase object that we use to compare against
 */
const gridDirty = (gridData, fc) : boolean => {
	// do we have more identities coming in to than on the original (add, delete)
	if (fc?.identities?.length !== gridData.length) return true;

	// otherwise walk the identities one by one...
	if (fc?.identities) {
		let isGridDirty = false;
		// eslint-disable-next-line no-restricted-syntax
		for (const [index, entity] of fc?.identities?.entries()) {
			// the first element will be `undefined` as we've turned off the gutter on grid where it used to be row id
			isGridDirty = isDirty(gridData[index].slice(1), entity) || isGridDirty;
			// either return on the first row that's been changed or continue checking
			if (isGridDirty) return true;
		}
	}
	// ok, nothing seems to have changed
	return false;
};

/**
 *
 * @param form
 * @param gridData
 * @param fc
 */
const dirty = (form, gridData, fc) : boolean => {
	// check the form - are there any changes to state?
	const isFormDirty = formDirty(form, fc);
	// next check the data grid - we need to compare each row with corresponding identity
	// don't consider any 'ignorable' identities - rows without any mandatory fields populated etc.
	const data = utils.persistableGridData(gridData);
	// now check these rows against the case's grid
	const isGridDirty = gridDirty(data, fc);
	// console.log(`isFormDirty = ${isFormDirty }`);
	// console.log(`isGridDirty = ${isGridDirty}`);
	return (isFormDirty || isGridDirty);
};

const rehydrateHeader = (fc, form, decisionCodes, providerTypes) => {
	if (fc === null) return;
	if (!fc?.id) return;

	const decisionCode = decisionCodes.find((pair) => pair.value === fc.requestedDecisionCode)?.value;
	const providerType = providerTypes.find((pair) => pair.value === fc.providerTypeCode)?.value;

	// reset the headers
	const resetWith = {
		presentedDate: fc.presentedDate,
		requestedDecisionCode: decisionCode,
		firstName: fc.firstName,
		lastName: fc.lastName,
		orgName: fc.orgName,
		country: fc.country,
		state: fc.state,
		dollarBills: fc.dollarBills ? fc.dollarBills : 0,
		middleInitial: fc.middleInitial,
		providerTypeCode: providerType,
		tipSource: fc.tipSource,
		notes: fc.notes ? fc.notes: '',
	}

	form.reset(
		resetWith,
		{
			keepErrors: false,
			keepDirty: false,
			keepIsSubmitted: false,
			keepTouched: false,
			keepIsValid: false,
			keepSubmitCount: false,
		}
	);
};

const rehydrateGrids = (fc, dataGridProps)  => {
	if (fc === null) return;
	if (!fc?.id) return;

	if (fc?.identities == null || !fc.identities.length) return;

	const grid = [];
	// eslint-disable-next-line no-restricted-syntax
	for (const [, entity] of fc.identities?.entries()) {
		grid.push(utils.toDataGridRow(entity));
	}
	dataGridProps.updateData(grid);
};

export const processor = {
	formDirty,
	gridDirty,
	dirty,
	buildUpdateRequest,
	validateUpdate,
	buildNewRequest,
	validateNew,
	rehydrateHeader,
	rehydrateGrids,
};

