import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Layout } from '@abyss/web/ui/Layout';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';

const packageInfo = require('../../../package.json');

const UserName = styled('div', {
  color: '$gray8',
  fontSize: '$sm',
  fontWeight: '$semibold',
});

const AlignLeft = styled('div', {
  textAlign: 'left',
});

const SubText = styled('div', {
  color: '$gray7',
  fontSize: '$xs',
  fontWeight: '$normal',
  marginLeft: 1,
});

export const AccountSection = ({ sidebarWidth, user }) => {
  return (
    <div>
      <Popover
        data-cy="account-details-container"
        align="start"
        width={280}
        height="100"
        title={
          <React.Fragment>
            <Layout.Group>
              <IconMaterial icon="account_box" color="$gray8" size="$lg" />
              <AlignLeft>
                <UserName data-cy="account-username">
                  {user?.firstName} {user?.lastName}
                </UserName>
                <SubText data-cy="account-msid">{user?.msid}</SubText>
                <SubText data-cy="account-email">{user?.email}</SubText>
                <br />
                <SubText data-cy="account-web-version">
                  Web Version: {packageInfo.version}
                </SubText>
                <SubText data-cy="account-api-version">
                  API Version: {user?.version}
                </SubText>
                <UserName> </UserName>
                {/* <br/> */}
                {/* For now we'll simply turn this off but we may want to expose it or something similar via roles */}
                {/* <SelectInput */}
                {/*  label="Your secure groups" */}
                {/*  placeholder="Search groups" */}
                {/*  isClearable */}
                {/*  isSearchable */}
                {/*  // value={'Find your group'} */}
                {/*  // onChange={setValue} */}
                {/*  onClear={() => ''} */}
                {/*  options={groups.map(g => ({"value": g.title, "label": g.title }))} */}

                {/* /> */}
              </AlignLeft>
            </Layout.Group>
          </React.Fragment>
        }
      >
        <Layout.Group>
          <IconMaterial icon="account_box" color="$gray8" size="$lg" />
          <AlignLeft>
            <UserName style={{ maxWidth: sidebarWidth }}>
              {user?.firstName} {user?.lastName}
            </UserName>
            <SubText>View profile</SubText>
          </AlignLeft>
        </Layout.Group>
      </Popover>
    </div>
  );
};
