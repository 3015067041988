import { useQuery } from "@abyss/web/hooks/useQuery";
// @ts-ignore
import GetPublish from './GetNess.gql';

export const usePublish = (options) => {
	return useQuery(GetPublish, {
		...options,
		url: '/api/graphql',
		requestPolicy: 'no-cache',
	});
};
