import { ajax } from '@src/common/ajax';
import { ApiUrl } from '@src/common/ajax/apiUrl';
import { AjaxResponse } from '@src/common/ajax/types';
import { UserResponse } from './types';
import { defaultUser } from './defaultUser';

export async function fetchUser(): Promise<AjaxResponse<UserResponse>> {
  const query = `
  query User {
    user {
      msid
      firstName
      lastName
      email
      version
    }
  }`;

  const body = JSON.stringify({ operationName: 'User', query });

  const defaultResponse: UserResponse = {
    data: {
      user: { ...defaultUser },
    },
  };

  return ajax(defaultResponse, ApiUrl.apiUser(), { body });
}
