import { useQuery } from "@abyss/web/hooks/useQuery";
// @ts-ignore
import GetWhoLoggedOn from './GetWhoLoggedOn.gql';

export const useWhoLoggedOn = (options) => {
	return useQuery(GetWhoLoggedOn, {
		...options,
		url: '/api/graphql',
		requestPolicy: 'no-cache',
	});
};
